<template>
  <v-dialog v-model="dialog" max-width="800">
    <v-card :loading="loading" :disabled="loading">
      <v-card-title>
        جزئیات گویندگی "{{ selectedData.title ? selectedData.title : "" }}"
        <v-spacer />

        <v-btn outlined color="blue" @click="dialog = false">
          بازگشت
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-row class="mt-4">
          <v-col cols="12">
            <small>صوت</small>
            <audio-player
              v-if="dialog && selectedData.voice"
              :src="selectedData.voice.url"
              flat
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            v-if="selectedData.user && selectedData.user.first_name"
          >
            <small>فروشنده</small>
            <h3 class="black--text">
              {{
                selectedData.user.first_name + " " + selectedData.user.last_name
              }}
            </h3>
          </v-col>
          <v-col cols="12" sm="6">
            <small>نام گویندگی</small>
            <h3 class="black--text">{{ selectedData.title }}</h3>
          </v-col>

          <v-col
            cols="12"
            sm="6"
            v-if="selectedData.category && selectedData.category.name"
          >
            <small>دسته بندی گویندگی</small>
            <br />
            <h3 class="black--text">{{ selectedData.category.name }}</h3>
          </v-col>
        </v-row>
        <h3 class="mt-5" v-if="selectedData.desc">توضیحات</h3>
        <h4 class="mt-4" v-if="selectedData.desc">{{ selectedData.desc }}</h4>
        <v-textarea
          class="mt-8 "
          auto-grow
          v-model="status_desc"
          outlined
          label="توضیحات وضعیت (رد/تایید)"
        />
        <v-row align="center" justify="end" class="mb-3">
          <v-btn class="ma-2" dark small color="red" @click="setStatus(3)">
            <v-icon small dark>mdi-close-thick</v-icon>
            رد گویندگی
          </v-btn>
          <v-btn class="ma-2" dark small color="success" @click="setStatus(2)">
            <v-icon small dark>mdi-check-bold</v-icon>

            تایید گویندگی
          </v-btn>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import MyAxios from "../../constants/MyAxios";
import AudioPlayer from "../AudioPlayer.vue";
export default {
  components: { AudioPlayer },
  data() {
    return {
      dialog: false,
      loading: false,
      status_desc: "",
      options: {
        autoplay: true,
      },
    };
  },
  props: {
    selectedData: {
      required: true,
      type: Object,
    },
  },
  watch: {
    dialog(val) {
      if (val === false) this.status_desc = "";
    },
    selectedData(val) {
      if (val.status_desc) this.status_desc = val.status_desc;
    },
  },
  methods: {
    setStatus(status) {
      this.loading = true;
      MyAxios.post(`/podcasts/${this.selectedData.id}/update`, {
        status,
      })
        .then((response) => {
          this.loading = false;
          this.dialog = false;
          this.$root.$emit("toast", {
            text: "وضعیت گویندگی با موفقیت تغییر کرد.",
            type: "success",
          });
          this.$emit("GetList");
          // this.GetList();
        })
        .catch((error) => {
          this.loading = false;
        });
    },
  },
};
</script>

<style></style>
